const ADVANCE_APPROVAL_PROCESS = {
  ADVANCE_RULE_ALREADY_EXISTS_ERROR: {
    description:
      "Já existe uma política cadastrada para o usuário selecionado.",
    title: "Política existente",
  },
  UNEXPECTED_ADVANCE_APPROVAL_RULES_DELETE_ERROR: {
    description: "Não foi possível excluir o processo selecionado.",
    title: "Erro inesperado",
  },
  UNEXPECTED_ADVANCE_APPROVAL_RULES_LIST_ERROR: {
    description:
      "Não foi possível carregar as políticas de aprovação de adiantamentos",
    title: "Erro inesperado",
  },
  UNEXPECTED_CREATE_ADVANCE_APPROVAL_RULE_ERROR: {
    description: "Não foi possível criar o processo de aprovação desejado.",
    title: "Erro inesperado",
  },
  UNEXPECTED_EDIT_ADVANCE_APPROVAL_RULE_ERROR: {
    description: "Não foi possível editar o processo e aprovação desejado.",
    title: "Erro inesperado",
  },
} as const;

const ADVANCED_EXPENSE_ERRORS = {
  UNEXPECTED_CREATE_ADVANCED_EXPENSE_ERROR: {
    description: "Não foi possível criar uma nova solicitação de adiantamento",
    title: "Erro inesperado",
  },
  UNEXPECTED_FETCH_ADVANCED_EXPENSE_DETAILS_ERROR: {
    description: "Não foi possível carregar o status do adiantamento",
    title: "Erro inesperado",
  },
  UNEXPECTED_FETCH_ADVANCED_EXPENSE_ERROR: {
    description: "Não foi possível carregar a lista de adiantamentos",
    title: "Erro inesperado",
  },
  UNEXPECTED_VINCULATE_ADVANCE_EXPENSE_REPORT: {
    description:
      "Não foi vincular a solicitação de adiantamento a um relatório de despesa",
    title: "Erro inesperado",
  },
} as const;

const ANALYTICS_ERRORS = {
  FILE_DOWNLOAD_ERROR: {
    description: "Não foi possível fazer o download do relatório selecionado.",
    title: "Erro inesperado",
  },
  FILTERS_LOAD_ERROR: {
    description: "Não foi possível carregar um ou mais filtros.",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_BUDGETS_ERROR: {
    description: "Não foi possível carregar os dados dos Centros de Custos.",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_PLANNED_SPENT_ERROR: {
    description:
      "Não foi possível carregar os valores gastos e planejados ao longo do tempo.",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_REAL_TIME_TRAVELS_ERROR: {
    description:
      "Não foi possível carregar as informações das viagens em tempo real.",
    title: "Erro inesperado",
  },
} as const;

const APPROVALS_ERRORS = {
  UNEXPECTED_APPROVALS_LIST_ERROR: {
    description:
      "Erro ao listar aprovações, atualize a página ou entre em contato o suporte",
    title: "Erro inesperado",
  },
} as const;

const AREAS_ERRORS = {
  UNEXPECTED_ADD_USER_TO_AREA_ERROR: {
    description: "Erro ao adicionar usuários a área.",
    title: "Erro inesperado",
  },
  UNEXPECTED_CREATE_AREA_ERROR: {
    description: "Não foi possível criar uma nova área.",
    title: "Erro inesperado",
  },
  UNEXPECTED_FETCH_AREA_ERROR: {
    description: "Não foi possível carregar as informações dessa área.",
    title: "Erro inesperado",
  },
  UNEXPECTED_FETCH_CLIENTS_AREAS_ERROR: {
    description: "Não foi possível listar as informações solicitadas.",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_USER_AREAS_ERROR: {
    description: "Erro ao carregar areas do viajante",
    title: "Erro inesperado",
  },
  UNEXPECTED_REMOVE_USER_FROM_AREA_ERROR: {
    description: "Erro ao remover usuários da área.",
    title: "Erro inesperado",
  },
  UNEXPECTED_UPDATE_AREA_ERROR: {
    description: "Erro ao atualizar as informações da área.",
    title: "Erro inesperado",
  },
  UNEXPECTED_UPDATE_DISPLAY_AREA_NAME_ERROR: {
    description: "Erro ao atualizar nome personalizado.",
    title: "Erro inesperado",
  },
} as const;

export const AUTHENTICATION_ERRORS = {
  AUTH_METHOD_NOT_ENABLED:
    "Client does not have this authentication method enabled.",
  EXPIRED_SESSION: "Session has expired.",
  MISSING_SSO_CONFIGS: "User has no sso configuration for its client.",
  REVOKED_SESSION: "Login has been revoked.",
  SESSION_NOT_FOUND: "Session not found.",
  SSO_CONFIGS_NOT_FOUND: "No sso config found.",
  USER_WITHOUT_ACCESS: "User has no accesss to this authentication method.",
};

export const AUTHENTICATION_ERRORS_MESSAGES = {
  [AUTHENTICATION_ERRORS.EXPIRED_SESSION]:
    "Sessão expirada, realize um novo login para continuar",
  [AUTHENTICATION_ERRORS.REVOKED_SESSION]:
    "Acesso revogado, realize o login novamente",
  [AUTHENTICATION_ERRORS.SSO_CONFIGS_NOT_FOUND]:
    "Nenhum configuração de sso encontrada",
  [AUTHENTICATION_ERRORS.USER_WITHOUT_ACCESS]:
    "Não foi possível atualizar a sessão atual, realize o login novamente",
};

const BILLING_PROFILE_CONFIGURATION_ERRORS = {
  UNEXPECTED_CREATE_BILLING_PROFILE_ERROR: {
    description: "Erro inesperado ao criar CNPJ.",
    title: "Erro inesperado",
  },
  UNEXPECTED_DELETE_BILLING_PROFILE_ERROR: {
    description: "Não foi possível excluir o CNPJ selecionado.",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_SINGLE_BILLING_PROFILE_ERROR: {
    description:
      "Não foi possível carregar as informações do CNPJ selecionado.",
    title: "Erro inesperado",
  },
  UNEXPECTED_LIST_CLIENT_BILLING_PROFILES_ERROR: {
    description: "Não foi possível listar os CNPJs.",
    title: "Erro inesperado",
  },
  UNEXPECTED_UPDATE_BILLING_PROFILE_ERROR: {
    description: "Erro inesperado ao atualizar CNPJ.",
    title: "Erro inesperado",
  },
} as const;

const BOARD_ERRORS = {
  UNEXPECTED_ADD_AREA_TO_BOARD_ERROR: {
    description: "Erro ao adicionar itens da diretoria.",
    title: "Erro inesperado",
  },
  UNEXPECTED_CREATE_BOARD_ERROR: {
    description: "Não foi possível criar uma nova diretoria.",
    title: "Erro inesperado",
  },
  UNEXPECTED_FETCH_BOARD_ERROR: {
    description: "Não foi possível carregar as informações dessa área.",
    title: "Erro inesperado",
  },
  UNEXPECTED_FETCH_CLIENTS_BOARDS_ERROR: {
    description: "Não foi possível listar as informações solicitadas.",
    title: "Erro inesperado",
  },
  UNEXPECTED_REMOVE_AREA_FROM_BOARD_ERROR: {
    description: "Erro ao remover itens da diretoria.",
    title: "Erro inesperado",
  },
  UNEXPECTED_UPDATE_BOARD_ERROR: {
    description: "Erro ao atualizar as informações de diretoria.",
    title: "Erro inesperado",
  },
} as const;

const BOOKER_TARGET_ERRORS = {
  UNEXPECTED_BOOKER_TARGET_SEARCH_ERROR: {
    description: "Erro ao procurar usuarios, tente novamente.",
    title: "Erro inesperado",
  },
  UNEXPECTED_SEARCH_BOOKING_TARGETS_ERROR: {
    description: "Erro ao procurar usuarios, tente novamente.",
    title: "Erro inesperado",
  },
} as const;

export const BOOKING_CONFIRMATION_ERRORS = {
  HOTEL_CONFIRMATION_NOT_ALLOWED_ERROR: {
    description: "A reserva acessada já foi aprovada, negada ou já expirou.",
    title: "Sem permissão",
  },
  UNEXPECTED_CONFIRM_HOTEL_BOOKING_REQUEST_ERROR: {
    description:
      "Não foi possível confirmar a reserva solicitada, tente novamente.",
    title: "Erro inesperado",
  },
  UNEXPECTED_DENY_HOTEL_BOOKING_REQUEST_ERROR: {
    description:
      "Não foi possível negar a reserva solicitada, tente novamente.",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_HOTEL_NEGOTIATION_DATA_ERROR: {
    description: "Erro ao carregar dados da reserva, tente novamente.",
    title: "Erro inesperado",
  },
} as const;

const BUDGET_ERRORS = {
  UNEXPECTED_BUDGET_CHECK_ERROR: {
    description: "Não foi possível checar os orçamentos dos centros de custo",
    title: "Erro inesperado",
  },
  UNEXPECTED_BUDGET_CREATE_ERROR: {
    description: "Não foi possível criar este orçamento.",
    title: "Erro inesperado.",
  },
  UNEXPECTED_BUDGET_EDIT_ERROR: {
    description: "Não foi possível editar este orçamento.",
    title: "Erro inesperado.",
  },
  UNEXPECTED_BUDGET_DELETE_ERROR: {
    description: "Não foi possível excluir este orçamento.",
    title: "Erro inesperado.",
  },
  UNEXPECTED_BUDGET_DETAIL_ERROR: {
    description:
      "Não foi possível carregar as informações do orçamento selecionado.",
    title: "Erro inesperado.",
  },
  UNEXPECTED_BUDGET_LIST_ERROR: {
    description:
      "Não foi possível carregar os orçamentos dos centros de custos cadastrados.",
    title: "Erro inesperado.",
  },
  UNEXPECTED_BUDGET_SETTINGS_STATUS_TOGGLE_ERROR: {
    description: "Não foi possível salvar as alterações feitas.",
    title: "Erro inesperado",
  },
  UNEXPECTED_DEPOSIT_ERROR: {
    description: "Não foi possível realizar o depósito.",
    title: "Erro inesperado.",
  },
  UNEXPECTED_OPERATIONS_LIST_ERROR: {
    description:
      "Não foi possível carregar o histórico de operações do centro de custo selecionado.",
    title: "Erro inesperado.",
  },
  UNEXPECTED_TRANSFER_ERROR: {
    description: "Não foi possível realizar a transferência.",
    title: "Erro inesperado.",
  },
  TRAVEL_VALUE_NOT_FOUND_BUDGET_CHECK_ERROR: {
    description:
      "Não foi possível estabelecer o valor da viagem. " +
      "Isso normalmente acontece quando os itens da viagem já foram ou estão sendo processados.",
    title: "Valor da viagem não encontrado",
  },
  UNEXPECTED_WITHDRAWAL_ERROR: {
    description: "Não foi possível realizar o saque.",
    title: "Erro inesperado.",
  },
} as const;

const BUS_ERRORS = {
  BUS_OFFER_INSERTION_ERROR: {
    description: "Não foi possível inserir a oferta ao itinerário",
    title: "Erro inesperado",
  },
  UNEXPECTED_BUS_DETAILS_ERROR: {
    description: "Não foi possível carregar a seleção de assentos.",
    title: "Erro inesperado",
  },
  UNEXPECTED_BUS_LIST_ERROR: {
    description: "Não foi possível carregar a lista de ônibus disponíveis.",
    title: "Erro inesperado",
  },
} as const;

const CAR_SEARCH_ERRORS = {
  UNEXPECTED_ADD_ADDITIONAL_TO_CAR_OFFER_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível alterar os adicionais da oferta selecionada.",
  },
  UNEXPECTED_ADD_CAR_OFFER_TO_TRAVEL_ERROR: {
    description: "Não foi possível adicionar a oferta selecionada a sua viagem",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_CAR_SUPPLIER_OFFER: {
    title: "Erro inesperado",
    description:
      "Não foi possível carregar o carro selecionado, tente novamente.",
  },
  UNEXPECTED_LOAD_CARS_LIST_ERROR: {
    description:
      "Não foi possível listar os carros para a pesquisa solicitada, tente novamente.",
    title: "Erro inesperado",
  },
  UNEXPECTED_SEARCH_CONDUCTORS: {
    title: "Erro inesperado",
    description:
      "Não foi possível carregar os condutores disponíveis para o carro selecionado, tente novamente.",
  },
} as const;

const CLIENT_REVIEW_ERRORS = {
  UNEXPECTED_CREATE_CLIENT_REVIEW_ERROR: {
    description: "Não foi possível criar o agendamento solicitado.",
    title: "Erro inesperado",
  },
  UNEXPECTED_CREATE_CLIENT_REVIEW_SOURCE_ERROR: {
    description:
      "Não foi possível atrelar as informações ao client review solicitado",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_CLIENT_REVIEW_ERROR: {
    description:
      "Não foi possível acessar os detalhes do agendamento selecionado.",
    title: "Erro inesperado",
  },
  UNEXPECTED_LIST_CLIENT_REVIEWS_ERROR: {
    description:
      "Não foi possível listar as informações de client review solicitadas.",
    title: "Erro inesperado",
  },
  UNEXPECTED_REMOVE_CLIENT_REVIEW_ERROR: {
    description: "Não foi possível remover o agendamento solicitado.",
    title: "Erro inesperado",
  },
  UNEXPECTED_REMOVE_CLIENT_REVIEW_SOURCE_ERROR: {
    description:
      "Não foi possível atualizar as informações do client review solicitado",
    title: "Erro inesperado",
  },
  UNEXPECTED_UPDATE_CLIENT_REVIEW_ERROR: {
    description:
      "Não foi possível salvar as alterações do agendamento solicitado.",
    title: "Erro inesperado",
  },
} as const;

const COST_CENTER_ERRORS = {
  COST_CENTER_ALREADY_EXISTS_ERROR: {
    description: "Já existe um centro de custo ativo com o nome enviado",
    title: "Nome já utilizado",
  },
  UNEXPECTED_COST_CENTER_ARCHIVE_ERROR: {
    description: "Não foi possível arquivar o centro de custo selecionado",
    title: "Erro inesperado",
  },
  UNEXPECTED_COST_CENTER_CREATE_ERROR: {
    description: "Não foi possível criar o centro de custo",
    title: "Erro inesperado",
  },
  UNEXPECTED_COST_CENTER_EDIT_ERROR: {
    description: "Não foi possível editar o centro de custo selecionado",
    title: "Erro inesperado",
  },
  UNEXPECTED_COST_CENTERS_LIST_ERROR: {
    description:
      "Não foi possível listar os centros de custo, atualize a página e tente novamente",
    title: "Erro inesperado",
  },
  UNEXPECTED_FETCH_COST_CENTER_BY_TOKEN_ERROR: {
    description:
      "Não foi possível acessar os dados do centro de custo solicitado",
    title: "Erro inesperado",
  },
  UNEXPECTED_LIST_TRAVELER_COST_CENTERS_ERROR: {
    description:
      "Não foi possível carregar as informações de centros de custos, atualize a página",
    title: "Erro inesperado",
  },
  UNEXPECTED_TOGGLE_COST_CENTERS_ACTIVE_ERROR: {
    description: "Não foi possível alterar o status de centros de custo",
    title: "Erro inesperado",
  },
} as const;

const CREDITS_ERRORS = {
  UNEXPECTED_TRAVELER_CREDITS_ERROR: {
    description: "Não foi possível obter os créditos disponíveis do viajante.",
    title: "Erro inesperado",
  },
  UNEXPECTED_USER_CREDITS_ERROR: {
    description: "Não foi possível obter os créditos disponíveis do usuário.",
    title: "Erro inesperado",
  },
} as const;

const EXPENSE_ADVANCE_APPROVAL_REVIEW_ERRORS = {
  UNEXPECTED_APPROVE_EXPENSE_ADVANCE_REQUEST_ERROR: {
    description: "Erro ao aprovar/pagar a solicitação de adiantamento.",
    title: "Erro inesperado",
  },
  UNEXPECTED_DENY_EXPENSE_ADVANCE_REQUEST_ERROR: {
    description: "Erro ao negar a solicitação de adiantamento.",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_EXPENSE_ADVANCE_APPROVER_ERROR: {
    description: "Não foi possível carregar os aprovadores do adiantamento.",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_EXPENSE_ADVANCE_DETAILS_ERROR: {
    description: "Não foi possível carregar os dados do adiantamento.",
    title: "Erro inesperado",
  },
} as const;

const EXPENSE_ADVANCES_APPROVALS_ERRORS = {
  UNEXPECTED_GET_EXPENSE_ADVANCE_APPROVALS_ERROR: {
    description: "Não foi possível carregar as aprovações pendentes",
    title: "Erro inesperado",
  },
} as const;

const EXPENSE_APPROVAL_PROCESS_ERRORS = {
  EXPENSE_RULE_ALREADY_EXISTS_ERROR: {
    description:
      "Já existe uma política cadastrada para o usuário selecionado.",
    title: "Política existente",
  },
  UNEXPECTED_CREATE_EXPENSE_APPROVAL_RULE_ERROR: {
    description: "Não foi possível criar o processo de aprovação desejado.",
    title: "Erro inesperado",
  },
  UNEXPECTED_EDIT_EXPENSE_APPROVAL_RULE_ERROR: {
    description: "Não foi possível editar o processo e aprovação desejado.",
    title: "Erro inesperado",
  },
  UNEXPECTED_EXPENSE_APPROVAL_RULES_DELETE_ERROR: {
    description: "Não foi possível excluir o processo selecionado.",
    title: "Erro inesperado",
  },
  UNEXPECTED_EXPENSE_APPROVAL_RULES_LIST_ERROR: {
    description:
      "Não foi possível carregar as políticas de aprovação de despesas",
    title: "Erro inesperado",
  },
  UNEXPECTED_EXPENSE_REPORT_APPROVE_RESPONSE_ERROR: {
    description:
      "Não foi possível aprovar a solicição enviada, tente novamente.",
    title: "Erro inesperado",
  },
  UNEXPECTED_EXPENSE_REPORT_DENY_RESPONSE_ERROR: {
    description:
      "Não foi possível negar a solicitação enviada, tente novamente.",
    title: "Erro inesperado",
  },
  UNEXPECTED_EXPENSE_REPORT_INVOICES_DOWNLOAD_ERROR: {
    description:
      "Não foi possível baixar os comprovantes do relatório solicitado",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_EXPENSE_REPORT_APPROVAL_STATUS_ERROR: {
    description: "Não foi possível carregar os dados de aprovação do relatório",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_REPORT_EXPENSES_POLICIES_ERROR: {
    description:
      "Não foi possível encontrar os dados de política do relatório solicitado",
    title: "Erro inesperado",
  },
  UNEXPECTED_USER_SEARCH_ERROR: {
    description: "Não foi possível o usuário desejado",
    title: "Erro inesperado",
  },
  USER_NOT_ALLOWED_TO_FETCH_REPORT_ERROR: {
    description: "Este usuário não pode acessar o relatório solicitado",
    title: "Permissão negada",
  },
} as const;

const EXPENSE_ERRORS = {
  UNEXPECTECD_REMOVE_EXPENSE_FROM_REPORT_ERROR: {
    description: "Não foi possível remover a despesa selecionada do relatório",
    title: "Erro inesperado",
  },
  UNEXPECTED_ADD_EXPENSES_TO_REPORT_ERROR: {
    description:
      "Não foi possível adicionar as despesas ao relatório solictiado",
    title: "Erro inesperado",
  },
  UNEXPECTED_CALCULATE_ROUTE_DISTANCE_ERROR: {
    description:
      "Não foi possível calcular a rota e o valor do percurso informado, tente novamente",
    title: "Erro inesperado",
  },
  UNEXPECTED_CANCEL_REPORT_APPROVAL_REQUEST_ERROR: {
    description:
      "Erro ao cancelar pedido de aprovação para reembolso de despesas, atualize a página tente novamente.",
    title: "Erro inesperado",
  },
  UNEXPECTED_CHECK_USER_EXPENSES_PROCESSES_ERROR: {
    description: "Erro ao procurar processos de aprovação de despesas",
    title: "Erro inesperado",
  },
  UNEXPECTED_EXPENSE_CREATION_ERROR: {
    description: "Não foi possível criar a despesa solicitada, tente novamente",
    title: "Erro inesperado",
  },
  UNEXPECTED_EXPENSE_DELETE_ERROR: {
    description: "Não possível excluir a desepesa solicitada, tente novamente",
    title: "Erro inesperado",
  },
  UNEXPECTED_EXPENSE_LIST_ERROR: {
    description:
      "Não foi possível retornar a lista de despesas, tente novamente",
    title: "Erro inesperado",
  },
  UNEXPECTED_EXPENSE_UPDATE_ERROR: {
    description:
      "Não foi possível atualizar a despesa solicitada, verifique os dados e tente novamente",
    title: "Erro inesperado",
  },
  UNEXPECTED_EXPENSES_REPORTS_LIST_ERROR: {
    description:
      "Não foi possível listar os relatórios solicitados, recarregue a página ou tente novamente",
    title: "Erro inesperado",
  },
  UNEXPECTED_FINISH_REPORT_ERROR: {
    description: "Não foi possível enviar o relatório solicitado",
    title: "Erro inesperado",
  },
  UNEXPECTED_GENERATE_KILOMETER_RECEIPT_ERROR: {
    description: "Erro ao gerar comprovante para a rota selecionada",
    title: "Erro inesperado",
  },
  UNEXPECTED_IMAGE_UPLOAD_URL_ERROR: {
    description: "Não foi possível realizar o upload do arquivo desejado",
    title: "Erro inesperado",
  },
  UNEXPECTED_RECEIPT_IMAGE_FETCH_ERROR: {
    description:
      "Não foi possível carregar o comprovante da desepesa selecionada",
    title: "Erro inesperado",
  },
  UNEXPECTED_REMOVE_EXPENSE_REPORT_ERROR: {
    description: "Erro ao excluir o relatório de despesas.",
    title: "Erro inesperado",
  },
  UNEXPECTED_REPORT_CREATE_ERROR: {
    description: "Não foi possível criar o relatório solicitado",
    title: "Erro inesperado",
  },
  UNEXPECTED_REPORT_CREATE_WITH_EXPENSES_ERROR: {
    description: "Não foi possível criar o relatório, tente novamente",
    title: "Erro inesperado",
  },
  UNEXPECTED_REPORT_DESCRIPTION_UPDATE_ERROR: {
    description: "Não foi possível atualizar a descrição da despesa",
    title: "Erro inesperado",
  },
  UNEXPECTED_REPORT_FETCH_ERROR: {
    description: "Não foi possível retornar os dados do relatório solicitado",
    title: "Erro inesperado",
  },
  UNEXPECTED_REPORT_SEND_APPROVAL_REQUEST_ERROR: {
    description: "Não foi possível enviar o relatório para aprovação.",
    title: "Erro inesperado",
  },
  UNEXPECTED_REPORT_TRAVEL_FETCH_ERROR: {
    description:
      "Não foi possível retornar os dados da viagem atrelada ao relatório, atualize sua página",
    title: "Erro inesperado",
  },
  UNEXPECTED_SIGNAL_MULTIPLE_PAYMENTS_ERROR: {
    description:
      "Erro ao realizar baixa de pagamento(s), atualize a página e tente novamente",
    title: "Erro inesperado",
  },
} as const;

const EXPENSE_POLICY_ERRORS = {
  UNEXPECTED_DELETE_EXPENSE_POLICY_ERROR: {
    description: "Não foi possível excluir a política solicitada",
    title: "Erro inesperado",
  },
  UNEXPECTED_EXPENSE_POLICY_CREATION_ERROR: {
    description:
      "Não foi possível criar a política solicitada, verifique os dados e tente novamente",
    title: "Erro inesperado",
  },
  UNEXPECTED_EXPENSE_POLICY_EDITION_ERROR: {
    description:
      "Não foi possível atualizar a política solicitada, verifique os dados e tente novamente",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_EXPENSE_POLICY_INFO_ERROR: {
    description: "Não foi possível recuperar os dados da política selecionada",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_TARGET_SUGGESTIONS_ERROR: {
    description: "Erro ao procurar alvos para política",
    title: "Erro inesperado",
  },
  UNEXPECTED_LIST_EXPENSE_POLICIES_ERROR: {
    description: "Não foi possível recuperar a lista de políticas de despesas",
    title: "Erro inesperado",
  },
} as const;

export const EXPENSES_CATEGORIES_ERRORS = {
  UNEXPECTED_CREATE_CLIENT_EXPENSES_CATEGORIES_ERROR: {
    description: "Erro ao criar categoria de despesas",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_CLIENT_EXPENSES_CATEGORIES_ERROR: {
    description: "Erro ao carregar categorias de despesas",
    title: "Erro inesperado",
  },
  UNEXPECTED_UPDATE_CLIENT_EXPENSES_CATEGORIES_ERROR: {
    description: "Erro ao atualizar categoria de despesas",
    title: "Erro inesperado",
  },
} as const;

const FINANCIAL_ERRORS = {
  UNEXPECTED_FINACIAL_STATEMENT_DOWNLOAD_ERROR: {
    description: "Não foi possível baixar o extrato solicitado",
    title: "Erro inesperado",
  },
  UNEXPECTED_LIST_INVOICE_SERVICES_ERROR: {
    description:
      "Não foi possível listar as notas do tipo de serviço selecionado",
    title: "Erro inesperado",
  },
  UNEXPECTED_LIST_SMARTRIPS_INVOICE_ERROR: {
    description:
      "Não foi possível encontrar as notas para o período solicitado",
    title: "Erro inesperado",
  },
  UNEXPECTED_TRANSACTIONS_INVOICES_DOWNLOAD_ERROR: {
    description: "Não foi possível baixar as notas selecionadas",
    title: "Erro inesperado",
  },
} as const;

const FLIGHT_LIST_ERRORS = {
  TIMEOUT_FLIGHT_LIST_ERROR: {
    description:
      "O tempo limite para obtenção das ofertas disponíveis foi atingido. " +
      "Se o problema persistir, por favor, entrar em contato pelo chat.",
    title: "Tempo limite atingido.",
  },
  UNEXPECTED_FLIGHT_LIST_ERROR: {
    description:
      "Não foi possível estabelecer a comunicação com nossos fornecedores. Por favor, entrar em contato pelo chat.",
    title: "Erro inesperado.",
  },
} as const;

const FLIGHT_ERRORS = {
  UNEXPECTED_FLIGHT_ERROR: {
    description: "Não foi possível obter informações sobre a busca.",
    title: "Erro inesperado",
  },
};

const FLIGHT_CHANGE_ERRORS = {
  UNEXPECTED_CREATE_FLIGHT_CHANGE_SESSION_ERROR: {
    description:
      "Não foi possível criar a sessão de alteração de voo, entre em contato com o suporte.",
    title: "Erro inesperado.",
  },
  UNEXPECTED_FLIGHT_CHANGE_LIST_ERROR: {
    description:
      "Não foi possível obter a lista de voos disponíveis, entre em contato com o suporte.",
    title: "Erro inesperado.",
  },
  UNEXPECTED_SELECT_FLIGHT_CHANGE_OPTIONS_ERROR: {
    description:
      "Não foi possível selecionar as opções de alteração de voo, entre em contato com o suporte.",
    title: "Erro inesperado.",
  },
  UNEXPECTED_GET_FLIGHT_CHANGE_SUMMARY_ERROR: {
    description:
      "Não foi possível obter o resumo da alteração de voo, entre em contato com o suporte.",
    title: "Erro inesperado.",
  },
  UNEXPECTED_RECALCULATE_FLIGHT_OFFER_ERROR: {
    description:
      "Não foi possível obter os valores da alteração de voo, entre em contato com o suporte.",
    title: "Erro inesperado.",
  },
  UNEXPECTED_REQUEST_FLIGHT_CHANGE_ERROR: {
    description:
      "Não foi possível solicitar a alteração de voo, entre em contato com o suporte.",
    title: "Erro inesperado.",
  },
  UNEXPECTED_GET_PAYMENT_METHOD_FOR_OFFER_CHANGE_ERROR: {
    description:
      "Não foi possível obter o meio de pagamento para a alteração de voo, entre em contato com o suporte.",
    title: "Erro inesperado.",
  },
  UNEXPECTED_CHECK_USER_PAY_FOR_TRAVEL_CHANGE_ERROR: {
    description:
      "Não foi possível verificar se o usuário pode pagar a alteração de voo, entre em contato com o suporte.",
    title: "Erro inesperado.",
  },
};

const HOTEL_REVIEWS_ERRORS = {
  UNEXPECTED_SUBMIT_HOTEL_REVIEW_ERROR: {
    description: "Não foi possível salvar a avaliação enviada.",
    title: "Erro inesperado",
  },
} as const;

const HOTEL_SEARCH_ERRORS = {
  UNEXPECTED_ADD_HOTEL_OFFER_ERROR: {
    description:
      "Não foi possível adicionar o quarto selecionado ao seu itineário, entre em contato com o suporte",
    title: "Erro inesperado",
  },
  UNEXPECTED_LOAD_HOTEL_DETAILS_ERROR: {
    description:
      "Não foi possível carregar as informações do hotel selecionado.",
    title: "Erro inesperado",
  },
  UNEXPECTED_LOAD_HOTEL_INFO_ERROR: {
    description: "Não foi possível listar os hotéis para pesquisa solicitada.",
    title: "Erro inesperado",
  },
  UNEXPECTED_LOAD_HOTEL_RECEPTION_HOURS_ERROR: {
    description:
      "Não foi possível carregar as horas de checkin e checkout do hotel selecionado.",
    title: "Erro inesperado",
  },
  UNEXPECTED_LOAD_HOTEL_ROOMS_ERROR: {
    description:
      "Não foi possível carregar as informações dos quartos do hotel selecionado.",
    title: "Erro inesperado",
  },
  UNEXPECTED_REMOVE_HOTEL_FROM_FAVOURITE_ERROR: {
    description:
      "Não foi possível remover o hotel selecionado da lista de favoritos",
    title: "Erro inesperado",
  },
  UNEXPECTED_SET_HOTEL_FAVOURITE_ERROR: {
    description: "Não foi possível favoritar o hotel selecionado",
    title: "Erro inesperado",
  },
} as const;

const INSURANCE_ERRORS = {
  UNEXPECTED_INSURANCE_GETTING_ERROR: {
    description: "Não foi possível adicionar a contratação do seguro",
    title: "Erro inesperado",
  },
  UNEXPECTED_INSURANCE_SETTING_ERROR: {
    description: "Não foi possível adicionar a contratação do seguro",
    title: "Erro inesperado",
  },
} as const;

const ITINERARY_ERRORS = {
  UNAUTHORIZED_ACCESS_TO_APPROVAL_REVIEW: {
    description:
      "Você não tem permissão para revisar essa solicitação no nível de aprovação em que ela se encontra. Verifique se ela já não foi aprovada por outra pessoa",
    title: "Acesso negado",
  },
  UNAUTHORIZED_ACCESS_TO_PAYMENT: {
    description: "Você não tem permissão para acessar esta página",
    title: "Acesso negado",
  },
  UNAUTHORIZED_ACCESS_TO_TRAVEL_PLAN: {
    description:
      "Você não tem permissão para acessar este itinerário. Apenas o viajante e o solicitante podem acessar esta viagem",
    title: "Acesso negado",
  },
  UNAUTHORIZED_PROCEED_FROM_TRAVEL_PLAN: {
    description: "Você não tem permissão para acessar esta página",
    title: "Acesso negado",
  },
  UNEXPECTED_ADD_TRAVELER_TO_OFFER_ERROR: {
    description: "Não foi possível adicionar o usuário a oferta",
    title: "Erro inesperado",
  },
  UNEXPECTED_CANCEL_OWN_APPROVAL_REQUEST: {
    description:
      "Não foi possível cancelar o seu pedido de aprovação de viagem",
    title: "Erro inesperado",
  },
  UNEXPECTED_CHANGE_CHECK_IN_STATUS_ERROR: {
    description: "Não foi possível alterar a opção selecionada",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_HOTEL_SUGGESTION_ERROR: {
    description: "Erro ao buscar local solicitado",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_ITINERARY_DOCUMENT_PENDENCIES: {
    description:
      "Não foi possível carregar as pendências de documentos da viagem",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_ITINERARY_INFO: {
    description: "Não foi possível carregar as informações do itinerário",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_ITINERARY_PENDENCIES: {
    description: "Não foi possível carregar as pendências da viagem",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_ITINERARY_SERVICES: {
    description:
      "Não foi possível carregar as informações das ofertas do itinerário",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_JUSTIFICATION_POLICY_ERROR: {
    description:
      "Não foi possível recuperar as políticas de justificativa da viagem.",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_TRAVEL_APPROVAL_HISTORY: {
    description: "Não foi possível carregar o histórico de aprovação da viagem",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_TRAVEL_APPROVAL_STATUS: {
    description: "Não foi possível carregar o status de aprovação da viagem",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_TRAVEL_POLICIES_PER_OFFER: {
    description:
      "Não foi possível carregar informações sobre as políticas das ofertas do itinerário",
    title: "Erro inesperado",
  },
  UNEXPECTED_NOT_NEEDED_APPROVAL_REQUEST: {
    description: "Não é necessário solicitar uma aprovação para esta viagem",
    title: "Erro inesperado",
  },
  UNEXPECTED_NOT_PENDING_APPROVAL_TRAVEL_APPROVAL_REVIEW_ACCESS: {
    description: "Não há um pedido de aprovação pendente para esta viagem",
    title: "Erro inesperado",
  },
  UNEXPECTED_PENDING_APPROVAL_TRAVEL_APPROVAL_REQUEST_ACCESS: {
    description: "Já existe um pedido de aprovação pendente para esta viagem",
    title: "Erro inesperado",
  },
  UNEXPECTED_REMOVE_TRAVELER_FROM_OFFER_ERROR: {
    description: "Não foi possível remover o usuário da oferta",
    title: "Erro inesperado",
  },
  UNEXPECTED_SEND_VOUCHER_EMAIL_ERROR: {
    description: "Não foi possível solicitar email com vouchers",
    title: "Erro inesperado",
  },
  UNEXPECTED_TRAVEL_INFO_EDIT_ERROR: {
    description: "Não foi possível editar as informações administrativas.",
    title: "Erro inesperado",
  },
  UNEXPECTED_TRAVEL_INFO_UPDATE_ERROR: {
    description: "Não foi possível atualizar os dados da viagem.",
    title: "Erro inesperado",
  },
  UNEXPECTED_TRAVEL_INVOICES_DONWLOAD_ERROR: {
    description: "Não foi possível baixar dos comprovantes da viagem",
    title: "Erro inesperado",
  },
  UNEXPECTED_TRAVEL_ITINERARY_SHARE_ERROR: {
    description: "Não foi possível compartilhar seu itinerário.",
    title: "Erro inesperado",
  },
  UNEXPECTED_USERS_FETCH_ERROR: {
    description: "Não foi possível recuperar a lista de usuários",
    title: "Erro inesperado",
  },
  UNEXPECTED_VOUCHER_DOWNLOAD_ERROR: {
    description: "Não foi possível baixar o voucher da viagem",
    title: "Erro inesperado",
  },
} as const;

const LOYALTY_PROGRAMS_ERRORS = {
  UNEXPECTED_CREATE_LOYALTY_PROGRAM_ERROR: {
    description: "Não foi possível criar o programa de fidelidade.",
    title: "Erro inesperado",
  },
  UNEXPECTED_DELETE_LOYALTY_PROGRAM_ERROR: {
    description: "Não foi possível excluir o programa de fidelidade.",
    title: "Erro inesperado",
  },
  UNEXPECTED_EDIT_LOYALTY_PROGRAM_ERROR: {
    description: "Não foi possível editar o programa de fidelidade.",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_LOYALTY_PROGRAMS_ERROR: {
    description: "Não foi possível carregar os programas de fidelidade.",
    title: "Erro inesperado",
  },
} as const;

const NEW_TRIP_ERRORS = {
  UNEXPECT_NEW_TRIP_SEARCH_ERROR: {
    description:
      "Não foi possível realizar a busca solicitada, revise os dados e tente novamente",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_BUS_AUTOSUGGEST_ERROR: {
    description: "Nenhum resultado encontrado",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_CAR_LOCATION_AUTOSUGGESION_ERROR: {
    description: "Nenhum resultado encontrado",
    title: "Erro inesperado",
  },
} as const;

const PAYMENT_METHODS_ERRORS = {
  INVALID_CARD_CREATION_ERROR: {
    description: "Verifique seus dados e tente novamente.",
    title: "Cartão Inválido",
  },
  UNEXPECTED_CARD_CREATION_ERROR: {
    description: "Não foi possível registrar o cartão desejado",
    title: "Erro inesperado",
  },
  UNEXPECTED_CARD_DELETION_ERROR: {
    description: "Não foi possível excluir o cartão solicitado",
    title: "Erro inesperado",
  },
  UNEXPECTED_CARD_EDITION_ERROR: {
    description: "Não foi possível editar o cartão desejado",
    title: "Erro inesperado",
  },
  UNEXPECTED_LIST_BILLING_PROFILE_VCNS_ERROR: {
    description: "Não foi possível listar os VCNs do CNPJ, atualize a página",
    title: "Erro inesperado",
  },
  UNEXPECTED_LIST_CARDS_ERROR: {
    description: "Não foi possível listar os cartões solicitados",
    title: "Erro inesperado",
  },
  UNEXPECTED_LIST_USER_CARDS_ERROR: {
    description:
      "Não foi possível listar seus cartões de crédito, atualize a página",
    title: "Erro inesperado",
  },
} as const;

const PAYMENT_ERRORS = {
  UNEXPECTED_APPROVAL_PAYMENT_ERROR: {
    description:
      "Não foi possível aprovar a compra antes de processar o pagamento.",
    title: "Erro inesperado",
  },
  UNEXPECTED_CHECK_PERMISSION_TO_PAY_ERROR: {
    description:
      "Não foi possível verificar se o usuário tem permissão para efetuar o pagamento da viagem",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_CLIENT_INFO: {
    description:
      "Não foi possível carregar as preferências de pagamento do cliente",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_COST_CENTER_BUDGETS_ERROR: {
    description:
      "Não foi possível acessar as informações dos centros de custo do viajante",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_PAYABLE_CARDS_ERROR: {
    description:
      "Não foi possível listar os meios de pagamento disponíveis para a viagem.",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_PAYMENT_OPTIONS_ERROR: {
    description:
      "Não foi possível listar os cartões disponíveis para o pagamento da viagem.",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_TRAVEL_PAYMENT: {
    description:
      "Não foi possível carregar as informações de pagamento da viagem",
    title: "Erro inesperado",
  },
  UNEXPECTED_GET_TRAVEL_FLIGHT_CREDITS_ERROR: {
    description:
      "Não foi possível verificar os créditos disponíveis para o viajante.",
    title: "Erro inesperado",
  },
} as const;

const POLICY_ERRORS = {
  UNEXPECTED_POLICY_CREATE_ERROR: {
    description: "Não foi possível criar a política de viagem.",
    title: "Erro inesperado.",
  },
  UNEXPECTED_POLICY_DELETE_ERROR: {
    description: "Não foi possível excluir a política de viagem.",
    title: "Erro inesperado.",
  },
  UNEXPECTED_POLICY_DETAIL_ERROR: {
    description:
      "Não foi possível carregar as informações da política selecionada.",
    title: "Erro inesperado.",
  },
  UNEXPECTED_POLICY_EDIT_ERROR: {
    description: "Não foi possível editar a política de viagem.",
    title: "Erro inesperado.",
  },
  UNEXPECTED_POLICY_LIST_ERROR: {
    description:
      "Não foi possível carregar as políticas de viagem cadastradas.",
    title: "Erro inesperado.",
  },
  UNEXPECTED_POLICY_TARGETS_LIST: {
    description:
      "Não foi possível carregar os participantes da política selecionada.",
    title: "Erro inesperado.",
  },
  UNEXPECTED_POLICY_TARGETS_SAVE: {
    description:
      "Não foi possível salvar os participantes da política selecionada.",
    title: "Erro inesperado.",
  },
  UNEXPECTED_TRAVEL_POLICIES_LIST: {
    description:
      "Não foi possível carregar as políticas das ofertas da viagem.",
    title: "Erro inesperado.",
  },
} as const;

const PROJECTS_CONFIGURATION_ERRORS = {
  PROJECT_NAME_ALREADY_TAKEN: {
    description: "Nome já utilizado em outro projeto.",
    title: "Nome inválido",
  },
  UNEXPECTED_GET_USER_PROJECTS_ERROR: {
    description: "Erro ao carregar projetos do viajante",
    title: "Erro inesperado",
  },
  UNEXPECTED_PROJECT_CREATION_ERROR: {
    description:
      "Não foi possível criar o projeto, revise os dados enviados e tente novamente.",
    title: "Erro inesperado",
  },
  UNEXPECTED_PROJECT_EDIT_FETCH_ERROR: {
    description: "Não foi possível carregar os dados do projeto a ser editado.",
    title: "Erro inesperado",
  },
  UNEXPECTED_PROJECT_EDITING_ERROR: {
    description:
      "Não foi possível editar o projeto, revise os dados enviados e tente novamente.",
    title: "Erro inesperado",
  },
  UNEXPECTED_PROJECT_LIST_ERROR: {
    description: "Não foi possível listar os projetos, tente novamente",
    title: "Erro inesperado",
  },
  UNEXPECTED_PROJECT_STATUS_CHANGE_ERROR: {
    description:
      "Não foi possível alterar o status deste projeto, tente novamente",
    title: "Erro inesperado",
  },
  UNEXPTECT_UPDATE_PROJECT_ENABLING_STATE_ERROR: {
    description: "Não foi possível atualizar a opção selecionada",
    title: "Erro inesperado",
  },
} as const;

const RECENT_SEARCHES_ERROR = {
  UNEXPECTED_RECENT_SEARCHES_ERROR: {
    description: "Não foi possível carregar as pesquisas recentes.",
    title: "Erro ao buscar pesquisas recentes.",
  },
} as const;

const REWARD_ERRORS = {
  UNEXPECTED_REWARD_POLICY_ERROR: {
    description: "Não foi possível obter a política de pontos do cliente.",
    title: "Erro inesperado",
  },
} as const;

const SUPPORT_EVALUATION_ERRORS = {
  UNEXPECTED_GET_EVALUATION_STATUS_ERROR: {
    description:
      "Não foi possível encontrar a avaliação de atendimento solicitada",
    title: "Erro inesperado",
  },
  UNEXPECTED_SUBMIT_SUPPORT_EVALUATION_ERROR: {
    description: "Não foi possível enviar a avaliação, tente novamente.",
    title: "Erro inesperado",
  },
  USER_NOT_ALLOWED_TO_EVALUATE: {
    description: "O usuário não possuí permissão para realizar essa avaliação.",
    title: "Usuário sem permissão",
  },
} as const;

const TAG_ERRORS = {
  UNEXPECTED_ADD_TAG_ERROR: {
    description: "Não foi possível adicionar a etiqueta à viagem.",
    title: "Erro inesperado",
  },
  UNEXPECTED_ARCHIVE_TAG_ERROR: {
    description: "Erro ao arquivar tag.",
    title: "Erro inesperado",
  },
  UNEXPECTED_CREATE_TAG_ERROR: {
    description: "Não foi possível criar a etiqueta.",
    title: "Erro inesperado",
  },
  UNEXPECTED_EDIT_TAG_ERROR: {
    description: "Erro ao editar etiqueta.",
    title: "Erro inesperado",
  },
  UNEXPECTED_LOAD_TAG_ERROR: {
    description: "Não foi possível carregar as etiquetas de viagem.",
    title: "Erro inesperado",
  },
  UNEXPECTED_UNARCHIVE_TAG_ERROR: {
    description: "Erro ao desarquivar tag.",
    title: "Erro inesperado",
  },
  UNEXPECTED_UPDATE_CONFIG_TAG_ERROR: {
    description: "Erro ao atualizar configuração de tag",
    title: "Erro inesperado",
  },
} as const;

const TRAVEL_APPROVAL_ERRORS = {
  UNEXPECTED_DENY_OFFER_APPROVAL_ERROR: {
    description:
      "Não foi possível sinalizar uma de suas ofertas como negada, atualize a página e tente novamente.",
    title: "Erro inesperado",
  },
  UNEXPECTED_UNAVAILABLE_DENY_OFFER_APPROVAL_ERROR: {
    description:
      "Não foi possível sinalizar uma de suas ofertas como indisponível, atualize a página e tente novamente.",
    title: "Erro inesperado",
  },
  UNEXPECTED_UNDENY_OFFER_APPROVAL_ERROR: {
    description:
      "Não foi possível sinalizar uma de suas ofertas como aprovada, atualize a página e tente novamente.",
    title: "Erro inesperado",
  },
} as const;

const TRAVEL_CANCELATION_ERRORS = {
  UNEXPECTED_CANCEL_TRAVEL_OFFERS_ERROR: {
    description:
      "Occoreu um erro ao tentar cancelar as ofertar selecionadas, entre em contato com nosso suporte",
    title: "Erro inesperado",
  },
} as const;

const TRAVELS_COUNT_ERRORS = {
  UNEXPECTED_TRAVELS_COUNT_ERROR: {
    description:
      "Ocorreu um erro ao tentar contabilizar as viagens. Por favor, tente novamente.",
    title: "Erro inesperado",
  },
} as const;

const TRAVELS_LIST_ERRORS = {
  UNEXPECTED_CHECK_TRAVEL_COPY_STATUS_ERROR: {
    description:
      "Não foi possível checar o status das viagens sendo replicadas.",
    title: "Erro inesperado",
  },
  UNEXPECTED_FETCH_COPYING_AND_ORIGINAL_TRAVELS_ERROR: {
    description:
      "Não foi possível carregar as viagens sendo replicadas no momento.",
    title: "Erro inesperado",
  },
  UNEXPECTED_LIST_TRAVELS_USERS_ERROR: {
    description:
      "Não foi possível carrega as informações dos viajantes, atualize a página.",
    title: "Erro inesperado",
  },
  UNEXPECTED_LIST_USER_TRAVELS_ERROR: {
    description:
      "Não foi possível carregar a lista de viagens, atualize a página ou tente novamente mais tarde.",
    title: "Erro inesperado",
  },
  UNEXPECTED_LOAD_POINTS_ERROR: {
    description: "Não foi possível carregar seus smartpoints.",
    title: "Erro inesperado",
  },
  UNEXPECTED_TRAVEL_LIST_DELETE_TRAVEL_ERROR: {
    description: "Erro ao excluir viagem.",
    title: "Erro inesperado",
  },
} as const;

const USER_ERRORS = {
  UNEXPECTED_DELETE_LINKED_AS_APPROVAL_TARGET_ERROR: {
    description:
      "O usuário está vinculado a um ou mais processos de aprovação. Remova-o como alvo antes de deletar",
    title:
      "Não é possível excluir, pois há processos de aprovação associados ao usuário",
  },
  UNEXPECTED_DELETE_LINKED_AS_APPROVER_ERROR: {
    description:
      "O usuário está vinculado a um ou mais Processos de Aprovação. Remova-o como aprovador antes de deletar",
    title:
      "Não é possível excluir, pois há processos de aprovação associados ao usuário",
  },
  UNEXPECTED_DELETE_USER_ERROR: {
    description: "Não foi possível excluir usuário",
    title: "Erro inesperado",
  },
} as const;

const USER_REVIEW_ERRORS = {
  UNEXPECTED_GET_USER_HOTEL_REVIEWS_ERROR: {
    description:
      "Não foi possível retornar as avaliações do usuário solicitado.",
    title: "Erro inesperado",
  },
} as const;

export const ERROR = {
  APPROVAL_RULE_DELETE_ERROR: {
    description: "Não foi possível excluir a regra de aprovação selecionada",
    title: "Erro Inesperado",
  },
  APPROVAL_RULE_PENDING_REQUESTS: {
    description:
      "Não foi possível editar/excluir a regra de aprovação pois há aprovações pendentes",
    title: "Ação não permitida",
  },
  CONNECTION: {
    description:
      "Verifique a conectividade de sua internet. Recarregue a página ou tente novamente mais tarde. Se o problema persistir, por favor, entrar em contato com nosso suporte, podemos estar em manutenção.",
    title:
      "Ops, parece que está havendo problemas de conexão com nosso servidor!",
  },
  DOCUMENTS_PAYMENT_ERROR: {
    description:
      "Verifique se o viajante já possui todos os documentos cadastrados.",
    title: "Documentos do viajante não encontrados",
  },
  ERROR_BUDGET_LIMIT_ERROR: {
    description:
      "O valor de sua compra ultrapassará o valor planejado para o centro de custos selecionado no mês vigente. " +
      "Verifique com o usuário administrador responsável e tente novamente.",
    title: "Limite do centro de custo alcançado",
  },
  ERROR_VAULT_LIMIT_ERROR: {
    description:
      "O saldo do cofre da empresa, no momento, é insuficiente para completar a compra dos itens selecionados. " +
      "Verifique com o usuário administrador responsável e tente novamente.",
    title: "Saldo do cofre insuficiente",
  },
  EXPIRED_SEARCH_ERROR: {
    description: "Faça uma nova busca para preços atualizados.",
    title: "Busca expirada",
  },
  GENERAL_BAD_REQUEST_ERROR: {
    description: "Verifique os dados e tente novamente",
    title: "Erro inesperado",
  },
  GENERAL_CONFLICT_ERROR: {
    description:
      "É provável que um registro similar já exista, verifique os dados",
    title: "Conflito inesperado",
  },
  GENERAL_CONNECTION_ERROR: {
    description:
      "Verifique a conectividade de sua internet. Recarregue a página ou tente novamente mais tarde. Se o problema persistir, por favor, entrar em contato com nosso suporte, podemos estar em manutenção.",
    title: "Erro de conexão",
  },
  GENERAL_FORBIDDEN_ERROR: {
    description:
      "Você não possui permissão o suficiente para realizar essa ação",
    title: "Erro inesperado",
  },
  GENERAL_GETTING_VERIFICATION_ERROR: {
    description:
      "Não foi possível verificar se o viajante já possui uma reserva, atualize a página",
    title: "Erro inesperado",
  },
  GENERAL_UNEXPECTED_ERROR: {
    description: "Erro Inesperado",
    title: "Erro Inesperado",
  },
  INSUFFICIENT_FUNDS_PAYMENT_ERROR: {
    description: "Escolha outro cartão e tente novamente.",
    title: "Saldo de cartão insuficiente.",
  },
  INVALID_CARD_PAYMENT_ERROR: {
    description: "Verifique os dados e tente novamente.",
    title: "Cartão Inválido",
  },
  NO_PERMISSION_APPROVAL_RESPONSE_ERROR: {
    description:
      "Não foi possível atualizar o status da solicitação. É possível que esta já tenha sido respondida por outra pessoa.",
    title: "Erro de permissão",
  },
  NOT_FOUND: {
    description: "Não encontramos a página que você está buscando",
    title: "Parece que você se perdeu!",
  },
  UNAUTHORIZED_ACCESS: {
    description: "Você não tem permissão para realizar essa ação",
    title: "Erro de permissão",
  },
  UNAUTHORIZED_ROUTE: {
    description: "Desculpe, você não tem acesso a essa parte do sistema",
    title: "Tem nome na lista?",
  },
  UNEXPECTED: {
    description:
      "Estamos passando por alguns ajustes. Voltaremos em breve. Caso o problema persista, por favor entre em contato com nosso suporte.",
    title: "Não é você, sou eu!",
  },
  UNEXPECTED_FUND_CHECK_ERROR: {
    description:
      "Por favor, entre em contato com nosso suporte para que possamos rapidamente resolver o problema.",
    title: "Houve uma falha na verificação da transação",
  },
  UNEXPECTED_PAYMENT_ERROR: {
    description:
      "Houve uma falha inesperada antes mesmo do processamento da sua compra. Por favor, entre em contato com nosso suporte para que possamos rapidamente resolver o problema.",
    title: "Houve uma falha na sua reserva",
  },
  HALF_INSUFFICIENT_FUNDS_NOT_CANCELED_PAYMENT_ERROR: {
    description:
      "ATENÇÃO: " +
      "Quando isso acontece, nosso procedimento padrão é automaticamente cancelar e estornar os itens comprados com sucesso. " +
      "Houve, porém, uma falha neste procedimento e já estamos trabalhando para cancelá-los manualmente. " +
      "Pode ficar tranquilo, entraremos em contato notificando o cancelamento. " +
      "Enquanto isso, fique à vontade para entrar em contato conosco.",
    title:
      "O saldo do seu cartão era insuficente para completar a compra de todos os itens.",
  },
  HALF_INSUFFICIENT_FUNDS_PAYMENT_ERROR: {
    description:
      "Pode ficar tranquilo, " +
      "todos itens agora cobrados em seu cartão foram cancelados com sucesso e serão todos estornados. " +
      "Escolha outro cartão e tente novamente.",
    title:
      "O saldo do seu cartão era insuficente para completar a compra de todos os itens.",
  },
  UNEXPECTED_GET_CUSTOM_FIELD_OPTIONS_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível retornar as opções de campo personalizado",
  },

  UNEXPECTED_ADD_CUSTOM_FIELD_OPTIONS_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível adiconar o campo personalizado, o campo ja existe",
  },

  UNEXPECTED_UPDATE_CUSTOM_FIELD_OPTIONS_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível atualizar o campo personalizado",
  },
  ORDER_BOOKING_ERROR: (errorData: { message: string }) => ({
    description: errorData.message,
    title: "Erro de itinerário",
  }),
  SEARCH_NOT_ALLOWED: {
    description: "Você não pode pesquisar hotéis nesse país.",
    title: "Busca não permitida",
  },
  UNEXPECTED_APPROVAL_CREATION_ERROR: {
    description: "Não foi possível criar a regra de aprovação",
    title: "Erro Inesperado",
  },
  UNEXPECTED_APPROVAL_EDITION_ERROR: {
    description: "Não foi possível editar a regra de aprovação",
    title: "Erro Inesperado",
  },
  UNEXPECTED_APPROVAL_LISTING_ERROR: {
    description: "Não foi possível carregar suas regras de aprovação",
    title: "Erro Inesperado",
  },
  UNEXPECTED_APPROVAL_RESPONSE_ERROR: {
    description:
      "Não foi possível atualizar o status da solicitação de aprovação conforme o desejado, tente novamente",
    title: "Erro inesperado",
  },
  UNEXPECTED_APPROVAL_STATUS_FETCH_ERROR: {
    description:
      "Não foi possível retornar as informações sobre o processo de aprovação desta viagem, tente novamente.",
    title: "Erro inesperado",
  },
  UNEXPECTED_APPROVAL_TARGET_LISTING_ERROR: {
    description: "Não foi possível carregar os alvos de aprovação",
    title: "Erro Inesperado",
  },
  UNEXPECTED_APPROVER_USERS_LIST_ERROR: {
    description: "Não foi possível encontrar o aprovador desejado",
    title: "Erro inesperado",
  },
  UNEXPECTED_AUTOSUGGEST_ERROR: {
    description: "Não foi possível retornar resultados para sua pesquisa",
    title: "Erro inesperado",
  },
  UNEXPECTED_FETCH_CLIENT_INFO_ERROR: {
    description:
      "Não foi possível recuperar as configurações de sua empresa, atualize a página",
    title: "Erro inesperado",
  },
  UNEXPECTED_PENDING_APPROVAL_TRAVEL_TOKENS_FETCH_ERROR: {
    description:
      "Não foi possível resgatar a lista de solicitações de aprovação feitas para seu usuário.",
    title: "Erro inesperado",
  },
  UNEXPECTED_TRAVEL_AVAILABILITIES_FETCH_ERROR: {
    description:
      "Não foi possível se comunicar com os nossos fornecedores. Caso o problema persista, por favor, entrar em contato com o suporte.",
    title: "Não foi possível verificar a disponibilidade",
  },
  UNEXPECTED_TRAVEL_FETCH_ERROR: {
    description:
      "Não foi possível retornar os dados de sua viagem, tente novamente mais tarde",
    title: "Erro inesperado",
  },
  UNEXPECTED_USER_FETCH_ERROR: {
    description:
      "Não foi possível retornar as informações do usuário solicitado, tente novamente.",
    title: "Erro inesperado",
  },
  UNEXPECTED_USER_LIST_FETCH_ERROR: {
    description: "Não foi possível carregar as informações dos usuários",
    title: "Erro inesperado",
  },
  ...ADVANCE_APPROVAL_PROCESS,
  ...ADVANCED_EXPENSE_ERRORS,
  ...ANALYTICS_ERRORS,
  ...APPROVALS_ERRORS,
  ...AREAS_ERRORS,
  ...BILLING_PROFILE_CONFIGURATION_ERRORS,
  ...BOARD_ERRORS,
  ...BOOKER_TARGET_ERRORS,
  ...BOOKING_CONFIRMATION_ERRORS,
  ...BUDGET_ERRORS,
  ...BUS_ERRORS,
  ...CAR_SEARCH_ERRORS,
  ...CLIENT_REVIEW_ERRORS,
  ...COST_CENTER_ERRORS,
  ...CREDITS_ERRORS,
  ...EXPENSE_ADVANCE_APPROVAL_REVIEW_ERRORS,
  ...EXPENSE_ADVANCES_APPROVALS_ERRORS,
  ...EXPENSE_APPROVAL_PROCESS_ERRORS,
  ...EXPENSE_ERRORS,
  ...EXPENSE_POLICY_ERRORS,
  ...EXPENSES_CATEGORIES_ERRORS,
  ...FINANCIAL_ERRORS,
  ...FLIGHT_LIST_ERRORS,
  ...FLIGHT_ERRORS,
  ...FLIGHT_CHANGE_ERRORS,
  ...HOTEL_REVIEWS_ERRORS,
  ...HOTEL_SEARCH_ERRORS,
  ...INSURANCE_ERRORS,
  ...ITINERARY_ERRORS,
  ...LOYALTY_PROGRAMS_ERRORS,
  ...NEW_TRIP_ERRORS,
  ...PAYMENT_METHODS_ERRORS,
  ...PAYMENT_ERRORS,
  ...POLICY_ERRORS,
  ...PROJECTS_CONFIGURATION_ERRORS,
  ...RECENT_SEARCHES_ERROR,
  ...REWARD_ERRORS,
  ...SUPPORT_EVALUATION_ERRORS,
  ...TAG_ERRORS,
  ...TRAVEL_APPROVAL_ERRORS,
  ...TRAVEL_CANCELATION_ERRORS,
  ...TRAVELS_COUNT_ERRORS,
  ...TRAVELS_LIST_ERRORS,
  ...USER_ERRORS,
  ...USER_REVIEW_ERRORS,
} as const;

export default {
  COST_CENTER_ALREADY_EXISTS: "COST_CENTER_ALREADY_EXISTS",
  EMAIL_ALREADY_EXIST: "EMAIL_ALREADY_EXIST",
  EMAIL_PASSWORD_INCORRECT: "EMAIL_PASSWORD_INCORRECT",
  ERROR_BOOKING_OFFERS: "ERROR_BOOKING_OFFERS",
  ERROR_BUDGET_LIMIT_ERROR: "ERROR_BUDGET_LIMIT_ERROR",
  ERROR_FINDING_TRAVEL_CURRENT_VALUE: "ERROR_FINDING_TRAVEL_CURRENT_VALUE",
  ERROR_FINDING_TRAVEL: "ERROR_FINDING_TRAVEL",
  ERROR_FINDING_USER: "ERROR_FINDING_USER",
  ERROR_GETTING_DOCUMENT: "ERROR_GETTING_DOCUMENT",
  ERROR_INSUFFICIENT_FUNDS: "ERROR_INSUFFICIENT_FUNDS",
  ERROR_INVALID_CARD: "ERROR_INVALID_CARD",
  ERROR_INVALID_CREDENTIALS: "Invalid user email or password",
  ERROR_INVALID_INPUT: "ERROR_INVALID_INPUT",
  ERROR_NOT_ALLOWED: "ERROR_NOT_ALLOWED",
  ERROR_PRIOR_BOOK_PROCESSING: "ERROR_PRIOR_BOOK_PROCESSING",
  ERROR_USER_DOCUMENTS: "ERROR_USER_DOCUMENTS",
  ERROR_VAULT_LIMIT_ERROR: "ERROR_VAULT_LIMIT_ERROR",
  EXPENSE_RULE_ALREADY_EXISTS: "EXPENSE_RULE_ALREADY_EXISTS",
  INSUFICCIENT_FUNDS_ERROR: "INSUFICCIENT_FUNDS_ERROR",
  INVALID_CREDIT_CARD: "INVALID_CREDIT_CARD",
  ORDER_BOOKING_ERROR: "ORDER_BOOKING_ERROR",
  PROJECT_ALREADY_EXISTS: "PROJECT_ALREADY_EXISTS",
  UNEXPECTED_FUND_CHECK_ERROR: "UNEXPECTED_FUND_CHECK_ERROR",
} as const;
